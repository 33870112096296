.footer {
    background-color: $blue;
    color: $white;
    padding: 20px;

    &__inner {
        max-width: 1140px;
        padding: 60px 0;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    &__col {
        flex: 1;
        margin-bottom: 2rem;

        &:nth-of-type(2) {

            @media (max-width: 767px) {
                margin-bottom: 0;
            }
        }

        &:nth-of-type(3) {

            h5 {
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }
    }

    &__title {
        color: $pink;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.4px;
        font-weight: bold;
        margin-top: 0;
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    &__list-item {
        display: flex;
        align-items: center;
        font-size: inherit;
        padding-bottom: 2px;
        line-height: 1.65;

        a {
            font-size: 15px;
            display: flex;
            align-items: center;
            font-size: inherit;
            box-shadow: none;
            text-decoration: none
        }
    }

    &__legal {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #FFFFFFc4;
    }
}
