@font-face {
    font-family: 'Likewize';
    src: url('../fonts/Likewize-Regular.woff2') format('woff2'),
        url('../fonts/Likewize-Regular.woff') format('woff'),
        url('../fonts/Likewize-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Likewize';
    src: url('../fonts/Likewize-Bold.woff2') format('woff2'),
        url('../fonts/Likewize-Bold.woff') format('woff'),
        url('../fonts/Likewize-Bold.ttf') format('truetype');
    font-weight: bold;
}

.t-headline {
    font-size: 4rem;
    letter-spacing: -0.001;
    font-weight: normal;
    margin: 0;

    @media (min-width: 500px) {
        font-size: 5rem;
    }
}

.t-section-heading {
    font-size: 3rem;
    letter-spacing: -0.001;
    font-weight: normal;
    margin: 0;

    @media (min-width: 500px) {
        font-size: 3.5rem;
    }
}

.t-heading {
    font-size: 2rem;
    font-weight: normal;
    margin: 0;

    @media (min-width: 500px) {
        font-size: 3rem;
    }
}

.t-tile-heading {
    font-size: 1.8rem;
    margin: 0;
    margin-bottom: 15px;

    @media (min-width: 500px) {
        font-size: 2.4rem;
    }
}

.t-tile-body {
    font-size: 1.6rem;
    font-weight: normal;
    margin: 0;
}

a {
    color: inherit;
    text-decoration: underline;
    transform: opacity 0.2s ease-in-out;

    &:hover {

        text-decoration: none;
        opacity: 0.8;
    }
}
