.banner {
    display: flex;
    flex-direction: column;
    max-height: 710px;
    overflow: hidden;
    display: none;

    &__image {

        img {
            display: flex;
            width: 100%;
            height: auto;
        }

    }

}
