.card {
    width: 100%;

    .card__grid & {

        @media (min-width: 500px) {
            display: flex;
            justify-content: flex-end;
        }

        .card__inner {
            margin-left: auto;
            margin-right: auto;

            @media (min-width: 1024px) {
                margin-left: auto;
                width: 100%;
            }
        }

        &:nth-child(even) {
            justify-content: flex-start;

            .card__inner {
                margin-left: auto;
                margin-right: auto;

                @media (min-width: 1024px) {
                    margin-right: auto;
                    margin-left: 0;
                }
            }
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;

        & > * {

            @media (min-width: 1024px) {
                flex: 0 0 50%;
            }
        }
    }

    &__inner {
        max-width: 1680px;
        margin-left: auto;
        margin-right: auto;
        padding: 40px;

        @media (min-width: 1024px) {
            padding: 65px;
        }

        &--narrow {
            padding: 0 40px 10px;

            @media (min-width: 1024px) {
                padding: 10px 65px 20px;
            }
        }

        &--form {
            padding: 20px;

            @media (min-width: 500px) {
                padding: 65px;
                padding-left: 0;
            }
        }

        &--flex-even {
            display: flex;

            & > * {
                flex: 1;
            }
        }

        .card__grid & {
            max-width: 840px;
            margin-left: 0;
            margin-right: 0;
        }
    }

    &--pink {
        background-color: $pink;
        color: $blue;
    }

    &--blue {
        background-color: $blue;
        color: $white;
    }

    &--white {
        background: $white;
        color: $blue;
    }

    &--grey {
        background-color: $cool-grey;
        color: $blue
    }

    &--m-white {
        @media (max-width: 1024px) {
            background: $white !important;
            color: $blue !important;
        }
    }

    &--m-grey {
        @media (max-width: 1024px) {
            background-color: $cool-grey !important;
            color: $blue !important;
        }

    }

    &--vert-centered {
        .card__inner {
            display: flex;
            align-items: center;
        }
    }

    &--centered {
        .card__inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    &__image {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        margin-bottom: 30px;
    }

    &__list {
        padding: 0;

        @media (min-width: 500px) {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 80%;
        }

        li {
            padding: 0 5px;
            margin: 0;
            list-style-type: none;

            @media (min-width: 500px) {
                &::after {
                    content: "|";
                    padding-left: 1rem;
                }

                &:nth-child(3) {
                    &::after {
                        content: "";
                    }
                }

                &:nth-child(4) {
                    &::after {
                        content: "";
                    }
                }

                &:nth-child(6) {
                    &::after {
                        content: "";
                    }
                }
            }

            @media (min-width: 667px) and (max-width: 1023px) {
                &:nth-child(3) {
                    &::after {
                        content: "|";
                    }
                }
            }

            @media (min-width: 1425px) {
                &:nth-child(3) {
                    &::after {
                        content: "|" !important;
                    }
                }
            }
        }

        &--under {
            li {
                &:nth-child(2) {
                    &::after {
                        content: "";
                    }
                }
            }
        }
    }
}
