.logos-grid {
    display: flex;
    flex-wrap: wrap;

    &__title {
        margin-bottom: 20px;
    }

    &__logo-container {
        flex: 0 0 calc(100% / 2);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;

        @media (min-width: 768px) {
            flex: 0 0 calc(100% / 7);
            padding: 40px 10px;
        }

        &--mobile {
            @media (min-width: 768px) {
                display: none;
            }
        }
    }
}
