.sl-hero {
    background-color: $blue;
    background-size: cover;
    color: $white;
    height: 95vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    @media (min-width: 500px) {
        height: 490px;
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0.5;
        object-fit: cover;
    }

    &__inner {
        position: relative;
        z-index: 20;
        opacity: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 1rem;
        text-align: center;
        font-weight: normal;
    }

    &__logo {
        height: 58px;
        width: auto;

        svg {
        fill: $pink;
        }
    }
}
