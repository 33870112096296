.form {
    font-size: 18px;
    margin: 0 20px;

    @media (min-width: 500px) {
        font-size: 22px;
    }

    &__group {
        margin-bottom: 35px;
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (min-width: 500px) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__label {
        display: inline-block;
        font-weight: bold;

        @media (min-width: 500px) {
            width: 150px;
            display: flex;
            justify-content: flex-end;
            text-align: right;
            margin-right: 10px;
        }
    }

    &__field {
        flex: 1;
    }

    input[type="text"],
    input[type="email"] {
        display: block;
        width: 100%;
        color: $pink;
        padding: 20px;
        font-size: 18px;
        outline: 0;
        border: 0;

        @media (min-width: 500px) {
            font-size: 22px;
        }

        .is-invalid & {
            border: 1px solid red;
        }
    }

    button {
        display: block;
        width: 100%;
        background-color: $blue;
        color: $white;
        padding: 20px;
        font-size: 22px;
        outline: 0;
        border: 0;
        border-radius: 1000px;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;

        &[disabled] {
            opacity: 0.5;
        }

    }

    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        transform: translateY(-0.075em);
        display: grid;
        place-content: center;
        flex: 0 0 auto;

        &::before {
            content: "";
            width: 0.66em;
            height: 0.66em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $blue;
            background-color: CanvasText;
        }

        &:checked::before {
            transform: scale(1);
        }
    }

    &__checkbox {

        display: flex !important;
        align-items: center;

        & + .form__checkbox {
            margin-top: 1em;
        }
    }

    &__terms {
        font-size: 10px;
        margin-left: 10px;
        text-align: left;
    }

    &__validation {
        display: none;

        .is-invalid & {
            display: block;
            color: red;
            font-size: 10px;
            margin-top: 5px;
        }
    }
}

.button-label {

    .processing & {
        display: none;
    }
}

.spinner {
    display: none;

    .processing & {
        display: block;
    }

    img {
        width: 1em;
        height: 1em;
    }
}
