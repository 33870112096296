@import './base/';
@import './components/';

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Likewize', 'Roboto', 'Arial', sans-serif;
    font-size: 15px;
    line-height: 1.2;
    color: #333;
    background-color: #fff;
    box-sizing: border-box;
}

// Shame

.shame-xs-center {
    @media (max-width: 500px){
        text-align: center;
    }
}

.lazy-item {
    opacity: 0;
    transform: translateY(20px) scale(0.95);
    transition: all 0.5s;
    &.lazy-loaded {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.js-terms-hidden-panel {
    height: 0px;
    overflow: hidden;
    transition: all 1000ms;
}
