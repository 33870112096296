@font-face {
  font-family: Likewize;
  src: url("Likewize-Regular.c4754152.woff2") format("woff2"), url("Likewize-Regular.ab82f070.woff") format("woff"), url("Likewize-Regular.c360c694.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: Likewize;
  src: url("Likewize-Bold.aa7a6719.woff2") format("woff2"), url("Likewize-Bold.28161b17.woff") format("woff"), url("Likewize-Bold.f0a9a99d.ttf") format("truetype");
  font-weight: bold;
}

.t-headline {
  letter-spacing: -.001px;
  margin: 0;
  font-size: 4rem;
  font-weight: normal;
}

@media (min-width: 500px) {
  .t-headline {
    font-size: 5rem;
  }
}

.t-section-heading {
  letter-spacing: -.001px;
  margin: 0;
  font-size: 3rem;
  font-weight: normal;
}

@media (min-width: 500px) {
  .t-section-heading {
    font-size: 3.5rem;
  }
}

.t-heading {
  margin: 0;
  font-size: 2rem;
  font-weight: normal;
}

@media (min-width: 500px) {
  .t-heading {
    font-size: 3rem;
  }
}

.t-tile-heading {
  margin: 0 0 15px;
  font-size: 1.8rem;
}

@media (min-width: 500px) {
  .t-tile-heading {
    font-size: 2.4rem;
  }
}

.t-tile-body {
  margin: 0;
  font-size: 1.6rem;
  font-weight: normal;
}

a {
  color: inherit;
  transform: opacity .2s ease-in-out;
  text-decoration: underline;
}

a:hover {
  opacity: .8;
  text-decoration: none;
}

.card {
  width: 100%;
}

@media (min-width: 500px) {
  .card__grid .card {
    justify-content: flex-end;
    display: flex;
  }
}

.card__grid .card .card__inner {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .card__grid .card .card__inner {
    width: 100%;
    margin-left: auto;
  }
}

.card__grid .card:nth-child(2n) {
  justify-content: flex-start;
}

.card__grid .card:nth-child(2n) .card__inner {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .card__grid .card:nth-child(2n) .card__inner {
    margin-left: 0;
    margin-right: auto;
  }
}

.card__grid {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 1024px) {
  .card__grid > * {
    flex: 0 0 50%;
  }
}

.card__inner {
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
}

@media (min-width: 1024px) {
  .card__inner {
    padding: 65px;
  }
}

.card__inner--narrow {
  padding: 0 40px 10px;
}

@media (min-width: 1024px) {
  .card__inner--narrow {
    padding: 10px 65px 20px;
  }
}

.card__inner--form {
  padding: 20px;
}

@media (min-width: 500px) {
  .card__inner--form {
    padding: 65px 65px 65px 0;
  }
}

.card__inner--flex-even {
  display: flex;
}

.card__inner--flex-even > * {
  flex: 1;
}

.card__grid .card__inner {
  max-width: 840px;
  margin-left: 0;
  margin-right: 0;
}

.card--pink {
  color: #3200be;
  background-color: #ff96ff;
}

.card--blue {
  color: #fff;
  background-color: #3200be;
}

.card--white {
  color: #3200be;
  background: #fff;
}

.card--grey {
  color: #3200be;
  background-color: #ebebf5;
}

@media (max-width: 1024px) {
  .card--m-white {
    color: #3200be !important;
    background: #fff !important;
  }

  .card--m-grey {
    color: #3200be !important;
    background-color: #ebebf5 !important;
  }
}

.card--vert-centered .card__inner {
  align-items: center;
  display: flex;
}

.card--centered .card__inner {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.card__image {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.card__list {
  padding: 0;
}

@media (min-width: 500px) {
  .card__list {
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }
}

.card__list li {
  margin: 0;
  padding: 0 5px;
  list-style-type: none;
}

@media (min-width: 500px) {
  .card__list li:after {
    content: "|";
    padding-left: 1rem;
  }

  .card__list li:nth-child(3):after, .card__list li:nth-child(4):after, .card__list li:nth-child(6):after {
    content: "";
  }
}

@media (min-width: 667px) and (max-width: 1023px) {
  .card__list li:nth-child(3):after {
    content: "|";
  }
}

@media (min-width: 1425px) {
  .card__list li:nth-child(3):after {
    content: "|" !important;
  }
}

.card__list--under li:nth-child(2):after {
  content: "";
}

.sl-hero {
  color: #fff;
  height: 95vh;
  background-color: #3200be;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 500px) {
  .sl-hero {
    height: 490px;
  }
}

.sl-hero__video {
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: .5;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.sl-hero__inner {
  z-index: 20;
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.sl-hero__title {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: normal;
}

.sl-hero__logo {
  height: 58px;
  width: auto;
}

.sl-hero__logo svg {
  fill: #ff96ff;
}

.form {
  margin: 0 20px;
  font-size: 18px;
}

@media (min-width: 500px) {
  .form {
    font-size: 22px;
  }
}

.form__group {
  width: 100%;
  flex-direction: column;
  margin-bottom: 35px;
  display: flex;
}

@media (min-width: 500px) {
  .form__group {
    flex-direction: row;
    align-items: center;
  }
}

.form__label {
  font-weight: bold;
  display: inline-block;
}

@media (min-width: 500px) {
  .form__label {
    width: 150px;
    text-align: right;
    justify-content: flex-end;
    margin-right: 10px;
    display: flex;
  }
}

.form__field {
  flex: 1;
}

.form input[type="text"], .form input[type="email"] {
  width: 100%;
  color: #ff96ff;
  border: 0;
  outline: 0;
  padding: 20px;
  font-size: 18px;
  display: block;
}

@media (min-width: 500px) {
  .form input[type="text"], .form input[type="email"] {
    font-size: 22px;
  }
}

.is-invalid .form input[type="text"], .is-invalid .form input[type="email"] {
  border: 1px solid red;
}

.form button {
  width: 100%;
  color: #fff;
  cursor: pointer;
  background-color: #3200be;
  border: 0;
  border-radius: 1000px;
  outline: 0;
  padding: 20px;
  font-size: 1rem;
  font-weight: bold;
  display: block;
}

.form button[disabled] {
  opacity: .5;
}

.form input[type="checkbox"] {
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  background-color: #fff;
  flex: none;
  place-content: center;
  margin: 0;
  display: grid;
  transform: translateY(-.075em);
}

.form input[type="checkbox"]:before {
  content: "";
  width: .66em;
  height: .66em;
  background-color: CanvasText;
  transition: transform .12s ease-in-out;
  transform: scale(0);
  box-shadow: inset 1em 1em #3200be;
}

.form input[type="checkbox"]:checked:before {
  transform: scale(1);
}

.form__checkbox {
  align-items: center;
  display: flex !important;
}

.form__checkbox + .form__checkbox {
  margin-top: 1em;
}

.form__terms {
  text-align: left;
  margin-left: 10px;
  font-size: 10px;
}

.form__validation {
  display: none;
}

.is-invalid .form__validation {
  color: red;
  margin-top: 5px;
  font-size: 10px;
  display: block;
}

.processing .button-label, .spinner {
  display: none;
}

.processing .spinner {
  display: block;
}

.spinner img {
  width: 1em;
  height: 1em;
}

.banner {
  max-height: 710px;
  flex-direction: column;
  display: none;
  overflow: hidden;
}

.banner__image img {
  width: 100%;
  height: auto;
  display: flex;
}

.logos-grid {
  flex-wrap: wrap;
  display: flex;
}

.logos-grid__title {
  margin-bottom: 20px;
}

.logos-grid__logo-container {
  flex: 0 0 50%;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
}

@media (min-width: 768px) {
  .logos-grid__logo-container {
    flex: 0 0 14.2857%;
    padding: 40px 10px;
  }

  .logos-grid__logo-container--mobile {
    display: none;
  }
}

.footer {
  color: #fff;
  background-color: #3200be;
  padding: 20px;
}

.footer__inner {
  max-width: 1140px;
  flex-direction: column;
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
}

@media (min-width: 768px) {
  .footer__inner {
    flex-direction: row;
  }
}

.footer__col {
  flex: 1;
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .footer__col:nth-of-type(2) {
    margin-bottom: 0;
  }

  .footer__col:nth-of-type(3) h5 {
    display: none;
  }
}

.footer__title {
  color: #ff96ff;
  text-transform: uppercase;
  letter-spacing: .4px;
  margin-top: 0;
  font-size: 16px;
  font-weight: bold;
}

.footer__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer__list-item {
  font-size: inherit;
  align-items: center;
  padding-bottom: 2px;
  line-height: 1.65;
  display: flex;
}

.footer__list-item a {
  font-size: 15px;
  font-size: inherit;
  box-shadow: none;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.footer__legal {
  width: 100%;
  text-align: center;
  color: #ffffffc4;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  color: #333;
  box-sizing: border-box;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Likewize, Roboto, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.2;
}

@media (max-width: 500px) {
  .shame-xs-center {
    text-align: center;
  }
}

.lazy-item {
  opacity: 0;
  transition: all .5s;
  transform: translateY(20px)scale(.95);
}

.lazy-item.lazy-loaded {
  opacity: 1;
  transform: translateY(0)scale(1);
}

.js-terms-hidden-panel {
  height: 0;
  transition: all 1s;
  overflow: hidden;
}

.toastify {
  color: #fff;
  opacity: 0;
  cursor: pointer;
  max-width: calc(50% - 20px);
  z-index: 2147483647;
  background: linear-gradient(135deg, #73a5ff, #5477f5);
  border-radius: 2px;
  padding: 12px 20px;
  text-decoration: none;
  transition: all .4s cubic-bezier(.215, .61, .355, 1);
  display: inline-block;
  position: fixed;
  box-shadow: 0 3px 6px -1px #0000001f, 0 10px 36px -4px #4d60e84d;
}

.toastify.on {
  opacity: 1;
}

.toast-close {
  color: #fff;
  cursor: pointer;
  opacity: .4;
  background: none;
  border: 0;
  padding: 0 5px;
  font-family: inherit;
  font-size: 1em;
}

.toastify-right {
  right: 15px;
}

.toastify-left {
  left: 15px;
}

.toastify-top {
  top: -150px;
}

.toastify-bottom {
  bottom: -150px;
}

.toastify-rounded {
  border-radius: 25px;
}

.toastify-avatar {
  width: 1.5em;
  height: 1.5em;
  border-radius: 2px;
  margin: -7px 5px;
}

.toastify-center {
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: -moz-fit-content;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 360px) {
  .toastify-right, .toastify-left {
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

/*# sourceMappingURL=index.6f14cd3c.css.map */
